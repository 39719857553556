const axios = require('axios');

const HOST = process.env.VUE_APP_HOST_VK;

const getAuthToken = async (dni, recaptchaToken) => {
  try {
    const API_URL = `${HOST}/token-client`;
    const data = {
      documento: dni,
      gcaptcha: recaptchaToken,
    };

    const resp = await axios({
      url: API_URL,
      method: 'POST',
      data,
    });
    return resp.data.token;
  } catch (error) {
    throw new Error(error.message);
  }
};
// eslint-disable-next-line
export { getAuthToken };
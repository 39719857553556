<template>
  <v-container grid-list-xs pa-6>
    <v-layout row justify-center>
      <v-flex xs12 md6 xl3 pa-2>
        <div class="px-4">
          <p class="text-center teal--text text-h4 font-weight-bold">¡Bienvenid@!</p>
          <p class="text-center font-weight-medium subtitle-1 py-4">
            A ser parte de la familia Konecta</p>
          <v-form ref="form" v-model="isValidWelcomeForm" :lazy-validation="lazy">
            <p key="typeDocument" ref="typeDocument"
              class="text-center black--text font-weight-bold subtitle-1 mb-5 mt-6">
              Tipo de documento
            </p>
            <v-select v-model="welcomeData.selectDocumentType" class="grey-label" color="teal"
              :items="availableDocuments" :rules="[(v) => !!v || 'El tipo de documento es requerido']"
              label="Tipo de documento" placeholder="Seleccionar" outlined required>
            </v-select>

            <p key="welcomeDocumentNumber" ref="welcomeDocumentNumber"
              class="text-center black--text font-weight-bold subtitle-1 mb-2 mt-2">
              Número de documento
            </p>
            <!-- Case: DNI -->
            <v-text-field class="grey-label" v-if="welcomeData.selectDocumentType !== 'Pasaporte' &&
                  welcomeData.selectDocumentType !== 'Permiso Temporal de Permanencia' &&
                  welcomeData.selectDocumentType !== 'Carnet de Extranjeria'" v-model="welcomeData.documentNumber"
              label="Número de documento" min="0" type="number" maxlength="8" outlined pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                  this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)" :rules="dniRules"
              placeholder="Ingrese el número" required oncopy="return true" onpaste="return true"></v-text-field>
            <!-- Case: Passport -->
            <v-text-field class="grey-label" v-if="welcomeData.selectDocumentType === 'Pasaporte'"
              v-model="welcomeData.documentNumber" maxlength="11" outlined label="Número de pasaporte" type="number"
              pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                  this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)"
              :rules="passportRules" placeholder="Ingrese el número de pasaporte" required oncopy="return false"
              onpaste="return false"></v-text-field>
            <!-- Case: Temporary Permit and Inmigration Card -->
            <v-text-field class="grey-label" v-if="
                  welcomeData.selectDocumentType === 'Permiso Temporal de Permanencia' ||
                  welcomeData.selectDocumentType === 'Carnet de Extranjeria'" v-model="welcomeData.documentNumber"
              maxlength="9" outlined label="Número de documento" type="number" pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                  this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)"
              :rules="temporaryPermitRules" placeholder="Ingrese el número de documento" required oncopy="return false"
              onpaste="return false"></v-text-field>
            <!-- DNI Confirmation -->
            <v-text-field class="grey-label" v-if="
                  welcomeData.selectDocumentType !== 'Pasaporte' &&
                  welcomeData.selectDocumentType !== 'Permiso Temporal de Permanencia' &&
                  welcomeData.selectDocumentType !== 'Carnet de Extranjeria'"
              v-model="welcomeData.documentConfirmationNumber" min="0" type="number" maxlength="8" outlined
              label="Confirmación de documento" pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                  this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)" :rules="dniRules"
              placeholder="Confirmación del número" required oncopy="return true" onpaste="return true"></v-text-field>
            <!-- Passport Confirmation -->
            <v-text-field class="grey-label" v-if="welcomeData.selectDocumentType === 'Pasaporte'"
              v-model="welcomeData.documentConfirmationNumber" maxlength="11" outlined label="Confirmación del número"
              type="number" pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                  this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)"
              :rules="passportRules" placeholder="Confirmación del número de pasaporte" required oncopy="return false"
              onpaste="return false"></v-text-field>
            <!-- Passport and CE confirmation -->
            <v-text-field class="grey-label" v-if="
                welcomeData.selectDocumentType === 'Permiso Temporal de Permanencia' ||
                welcomeData.selectDocumentType === 'Carnet de Extranjeria'"
              v-model="welcomeData.documentConfirmationNumber" maxlength="9" outlined label="Confirmación del número"
              type="number" pattern="[0-9]*" oninput="javascript: if (this.value.length > this.maxLength)
                this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)"
              :rules="temporaryPermitRules" placeholder="Confirmación del número de documento" required
              oncopy="return false" onpaste="return false">
            </v-text-field>
            <!-- ALERT: Documents not match -->
            <v-alert v-if="dniNotMatch" class="my-5 px-5 mx-4" text dense color="red">
              No se encontraron coincidencias, verifique el número de
              documento y vuelva a ingresar en el campo.
            </v-alert>

            <v-row class="justify-center py-6">
              <v-checkbox color="teal" v-model="privacyPolicyCheckbox"
                :rules="[(v) => !!v || '¡Acepta la política de Privacidad!']" required
                @click="()=> trackCheckPoliticaPriv()">
                <template v-slot:label>
                  <div @click.stop style="font-size: 16px" class="black--text">
                    He leído y acepto la
                    <a style="background: none" href="javascript:;" color="teal" class="teal--text"
                      @click.stop="termsAndConditions = true">
                      Política de Privacidad de los postulantes
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-row>
            <div class="d-flex justify-center">
              <v-btn width="250" height="45" :loading="!isLoadingSend" :disabled="!isValidWelcomeForm" color="teal"
                class="white--text" @click="validateIfIsRegisteredPostulant">
                Enviar</v-btn>
            </div>
          </v-form>
          <!-- <RegisteredPostulantModal
            v-model="registeredPostulantModal"
            :registeredPostulant="postulantData"
            :daysOffDifference="daysOffDifference"
          /> -->
          <TermsAndConditionsModal v-model="termsAndConditions" />
          <ModalValidate v-model="showModalValidate" :type="modalType" :postulantData="postulantData" />
        </div>
        <AlertComponent :show.sync="alert.show" :message="alert.message" :type="alert.type" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getAuthToken } from '../../services/auth/auth';
import { getPostulantByDoc, validateDocumentWithIGC } from '../../services/dynamo/postulants';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'welcomePage',
  components: {
    TermsAndConditionsModal: () => import('./terms_and_conditions_modal.vue'),
    ModalValidate: () => import('./modals/ModalValidate.vue'),
    AlertComponent: () => import('../Notification.vue'),
    // RegisteredPostulantModal: () => import('./is_registered_postulant.vue'),
  },
  props: {
    welcomeData: Object,
  },
  data: () => ({
    LIST_CODE_ERRORS: [
      'ERR_POSTULATION_HAS_OPEN_PROCESS_ANOTHER_IGC',
    ],
    isValidWelcomeForm: true,
    availableDocuments: [
      'DNI',
      'Pasaporte',
      'Carnet de Extranjeria',
      'Permiso Temporal de Permanencia',
    ],
    dniRules: [
      (v) => !!v || 'El número de documento es requerido',
      (v) => (v && v.length === 8) || 'El número debe ser de 8 carácteres',
    ],
    passportRules: [
      (v) => !!v || 'El número de documento es requerido',
      (v) => (v && v.length === 11) || 'El número debe ser de 11 carácteres',
    ],
    temporaryPermitRules: [
      (v) => !!v || 'El número de documento es requerido',
      (v) => (v && v.length === 9) || 'El número debe ser de 9 carácteres',
    ],
    dniNotMatch: false,
    privacyPolicyCheckbox: false,
    termsAndConditions: false,
    isLoadingSend: true,
    registeredPostulantModal: false,
    postulantData: {},
    daysOffDifference: 0,
    authToken: '',
    lazy: false,
    modalType: '',
    showModalValidate: false,
    alert: {
      show: false,
      message: '',
      type: 'info'
    },
  }),
  methods: {
    showAlert(message, type) {
      this.alert = {
        show: true,
        message: message,
        type: type
      };
    },
    trackCheckPoliticaPriv() {
      this.$gtag.event('LP_check_PoliticaDePrivacidad', {
        event_category: 'LP_check_PoliticaDePrivacidad',
        event_label: 'LP_check_PoliticaDePrivacidad',
        value: 1,
      });
    },
    trackbtnEnviarValidacion() {
      this.$gtag.event('LP_btn_EnviarValidacionDoc.', {
        event_category: 'LP_btn_EnviarValidacionDoc.',
        event_label: 'LP_btn_EnviarValidacionDoc.',
        value: 1,
      });
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      const event = evt || window.event;
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31
        && (charCode < 48 || charCode > 57)
        && charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleError(error) {
      const errorCode = error?.response?.data?.code;
      switch (errorCode) {
        case 'ERR_PROCESS_NOT_FOUND':
          return {
            processNotFound: true,
          };
        default:
          if (this.LIST_CODE_ERRORS.includes(errorCode)) {
            return {
              ...error?.response?.data?.payload,
              hasOpenProcess: true,
            };
          }
          throw error;
      }
    },
    async getValidationPostulant (documentNumber, igc = '') {
      try {
        if (!igc) {
          const hash = this.$route?.params?.igc;
          igc = hash;
        }

        const data = await validateDocumentWithIGC(igc, documentNumber);
        return data?.data;
      } catch (error) {
        return this.handleError(error);
      }
    },
    evaluatePostulantStatus(dataPostulant, igc) {
      const { testLink } = dataPostulant;
      if (!dataPostulant) {
        return;
      }

      const { isBlackList, completedForm, finishedTest, hasOpenProcess, isWorkingKonecta, processNotFound } = dataPostulant;

      switch (true) {
        case isBlackList:
          this.modalType = 'blackList';
          this.showModalValidate = true;
          break;
        case completedForm && !finishedTest:
          this.modalType = 'pendingTest';
          if (testLink) {
            sessionStorage.setItem('link-evaluar', testLink);
          }
          this.showModalValidate = true;
          break;
        case completedForm && finishedTest:
          this.modalType = 'completedTest';
          this.showModalValidate = true;
          break;
        case hasOpenProcess:
          this.modalType = 'hasOpenProcess';
          this.showModalValidate = true;
          break;
        case isWorkingKonecta:
          this.modalType = 'isWorkingKonecta';
          this.showModalValidate = true;
          break;
        case processNotFound:
          this.modalType = 'processNotFound';
          this.showModalValidate = true;
          break;
        default:
          sessionStorage.setItem('igc-valid', igc);
          this.$emit('hideWelcomePage');
      }
    },
    async validateIfIsRegisteredPostulant() {
      try {
        this.trackbtnEnviarValidacion();
        this.isLoadingSend = false;
        this.isLoading = true;

        const { documentNumber, documentConfirmationNumber } = this.welcomeData;

        if (documentNumber !== documentConfirmationNumber) {
          this.dniNotMatch = true;
          this.isLoadingSend = true;
          return;
        }

        this.dniNotMatch = false;

        const igc = this.$route?.params?.igc;

        if (!this.$refs.form.validate()) {
          return;
        }

        const dataPostulant = await this.getValidationPostulant(documentNumber, igc);
        this.postulantData = dataPostulant;
        this.evaluatePostulantStatus(dataPostulant, igc);

        this.isLoadingSend = false;
      } catch (error) {
        sessionStorage.removeItem('igc');
        this.showAlert('Ocurrió un error durante la validación. Inténtalo de nuevo.', 'error');
        console.error(error);
      } finally {
        this.isLoading = false;
        this.isLoadingSend = true;
      }
    },
    async getRecaptchaToken() {
      try {
        return await this.$recaptcha('login');
      } catch (error) {
        console.error(error,'error captcha');
      }
    },
    async getPostulantData() {
      const data = [];
      const { documentNumber } = this.welcomeData;
      const recaptchaToken = await this.getRecaptchaToken();
      this.authToken = await getAuthToken(documentNumber, recaptchaToken);
      sessionStorage.setItem('authToken', this.authToken);
      const recaptchaToken2 = await this.getRecaptchaToken();
      const allPostulantByDoc = await getPostulantByDoc(
        documentNumber, this.authToken, recaptchaToken2,
      );
      if (allPostulantByDoc.length > 0) {
        const index = allPostulantByDoc.length - 1;
        data.push({
          Apellidos: `${allPostulantByDoc[index].apellido_p.toUpperCase()} ${
            allPostulantByDoc[index].apellido_m.toUpperCase()
          }`,
          Nombre: allPostulantByDoc[index].nombres.toUpperCase(),
          registerDate: allPostulantByDoc[index].RegistradoDate.milliseconds,
        });
      }
      this.postulantData = data;
    },
  },
};
</script>

<style>
.v-input__slot fieldset{
    color: rgb(131, 197, 197) !important;
    border-width: 0.5px !important;
}

.v-input--is-focused .v-input__slot fieldset  {
   color: teal !important;
   border-width: 2px !important;
}

.grey-label .v-label {
    color: rgb(173, 173, 187) !important;
}

.v-input--is-focused .v-label {
    color: teal !important;
}
</style>
